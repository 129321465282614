// src/hooks/useAuth.ts
import { useUser, useAuth as useClerkAuth } from '@clerk/clerk-react';

interface EmailAddress {
  emailAddress: string;
}

interface UserInfo {
  firstName?: string;
  lastName?: string;
  imageUrl?: string; // Changed from profileImageUrl to imageUrl
  emailAddresses: EmailAddress[];
}

interface UseAuthReturn {
  isLoaded: boolean;
  isSignedIn: boolean;
  user: UserInfo | null;
  getToken: (options?: { template?: string }) => Promise<string | null>;
  signOut: () => void;
}

const useAuth = (): UseAuthReturn => {
  const { isLoaded, isSignedIn, user } = useUser();
  const { getToken, signOut } = useClerkAuth();

  // Transform Clerk's user object to match UserInfo interface
  const transformedUser: UserInfo | null = user
    ? {
        firstName: user.firstName ?? undefined,
        lastName: user.lastName ?? undefined,
        imageUrl: user.imageUrl ?? undefined, // Correct property
        emailAddresses: user.emailAddresses.map((email) => ({
          emailAddress: email.emailAddress,
        })),
      }
    : null;

  return {
    isLoaded: isLoaded ?? false, // Ensure boolean
    isSignedIn: isSignedIn ?? false, // Ensure boolean
    user: transformedUser,
    getToken,
    signOut,
  };
};

export default useAuth;
