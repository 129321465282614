// /src/components/chat/ChatListBar.tsx

import React from 'react';
import { Box, List, ListItemButton, ListItemText, Tooltip } from '@mui/material';
import { Icons } from '../../assets/Icons';
import { Chat } from '../../hooks/useChat'; // Import Chat interface

interface ChatListBarProps {
  chats: Chat[];
  onSelectChat: (chat: Chat) => void; // New prop
}

const ChatListBar: React.FC<ChatListBarProps> = ({ chats, onSelectChat }) => {
  return (
    <Box
      position="fixed"
      top="50%"
      right="0%"
      bgcolor="#1E1E1E"
      borderRadius="20px 0px 0px 20px"
      boxShadow={3}
      zIndex={1000}
      sx={{
        transform: 'translateY(-50%)',
        transition: 'width 0.3s',
        width: '40px',
        height: '265px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
          width: '200px',
        },
        // When the sidebar is hovered, make the text visible
        '&:hover .listItemText': {
          opacity: 1,
          visibility: 'visible',
        },
      }}
    >
      {/* Chat List */}
      <List sx={{py:'0px',}}>
        {chats.map((chat) => (
          <Tooltip title={chat.title} key={chat.id} placement="left">
            <ListItemButton
              onClick={() => onSelectChat(chat)}
              sx={{
                borderBottom: '1px solid #141517',
                display: 'flex',
                alignItems: 'center',
                paddingY: '10px',
                paddingX: '10px',
                '&:hover': {
                  backgroundColor: '#2A2A2A',
                },
              }}
            >
              {/* Fixed-Size Container for the Icon */}
              <Box
                sx={{
                  width: '25px', // Fixed width
                  height: '25px', // Fixed height
                  flexShrink: 0, // Prevent shrinking
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {Icons.chatsideIcon({
                  color: '#D9D9D9', // Adjust the color as needed
                  size: 16, // Fixed size
                })}
              </Box>

              {/* ListItemText with conditional display */}
              <ListItemText
                primary={chat.title}
                className="listItemText" // Assign a class for styling
                sx={{
                  marginLeft: '10px',
                  color: 'white',
                  transition: 'opacity 0.3s, visibility 0.3s',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  // Initially hide the text
                  opacity: 0,
                  visibility: 'hidden',
                }}
              />
            </ListItemButton>
          </Tooltip>
        ))}
      </List>
    </Box>
  );
};

export default ChatListBar;
