export const brandRed = '#DD2A4C'
export const brandPurple = '#A44890'
export const brandGreen = '#A9BB23'
export const brandCyan = '#00B3E3'

// project main colors
export const brandPink = '#FB7EB9'
export const brandBlue = '#32B3D2'
export const brandBG = '#141517'
export const brandGray = '#3A3A3A'



export const brandPrimary = '#DAEBEE'
export const brandSecondary = '#FE5B48'
export const brandGrayBG = '#EBEBEB'
export const brandBlueBG = '#CADDE1'
export const brandGray50 = '#898A8D'
export const brandGrayBlue = '#A2CFD9'

export const brandGray100 = '#FCFCFD'
export const brandGray200 = '#F9FAFB'
export const brandGray400 = '#98A2B3'
export const brandGray500 = '#667085'
export const brandGray300 = '#D0D5DD'
export const brandGray700 = '#344054'
export const brandGray900 = '#101828'

export const brandError500 = '#F04438'

const brandBlue50 = '#EFF8FF'
const brandLight = '#fff'
const brandWhite = '#fff'

interface Size {
  xs: string
  sm: string
  md: string
  lg: string
  xl: string
  xxl: string
}

interface FontWeight {
  regular: number
  semibold: number
  bold: number
}

export interface SpacingProps {
  $mt?: number
  $mb?: number
  $ml?: number
  $mr?: number
  $m?: number
  $pt?: number
  $pb?: number
  $pl?: number
  $pr?: number
  $p?: number
}

const size: Size = {
  xs: '0px', // for small screen mobile
  sm: '414px', // for mobile screen
  md: '767px', // for tablets
  lg: '1280px', // for laptops
  xl: '1440px', // for desktop / monitors
  xxl: '1920px', // for big screens
}
const fontWeights: FontWeight = {
  regular: 400,
  semibold: 600,
  bold: 700,
}

const colors = {
  brandRed: brandRed,
  brandPurple: brandPurple,
  brandBlue: brandBlue,
  brandGreen: brandGreen,
  brandCyan: brandCyan,
  brandPink: brandPink,
  brandWhite: brandWhite,
  brandGray: brandGray,
  brandGray100: brandGray100,
  brandGray200: brandGray200,
  brandGray300: brandGray300,
  brandGray400: brandGray400,
  brandGray500: brandGray500,
  brandGray700: brandGray700,
  brandGray900: brandGray900,
  brandBlue50: brandBlue50,
  brandGray50: brandGray50,
  brandDefault: brandGray900,
  brandError500: brandError500,
  brandPrimary: brandPrimary,
  brandSecondary: brandSecondary,
  brandGrayBG: brandGrayBG,
  brandBlueBG: brandBlueBG,
  brandBG:brandBG,
  brandGrayBlue:brandGrayBlue,
}

const theme = {
  colors: {
    'brand-primary': brandPrimary,
    'brand-secondary': brandSecondary,
    'brand-blue-bg': brandBlueBG,
    'brand-gray-bg': brandGrayBG,
    'brand-bg':brandBG,
    'brand-gray-blue':brandGrayBlue,
    'brand-red': brandRed,
    'brand-purple': brandPurple,
    'brand-blue': brandBlue,
    'brand-green': brandGreen,
    'brand-cyan': brandCyan,
    'brand-pink': brandPink,
    'brand-gray': brandGray,
    'brand-gray-400': brandGray400,
    'brand-gray-500': brandGray500,
    'brand-gray-700': brandGray700,
    'brand-gray-900': brandGray900,
    'brand-light': brandLight,
    'brand-white': brandWhite,
    'brand-default': brandGray900,
  },
  device: {
    xs: `(min-width: ${size.xs})`,
    sm: `(min-width: ${size.sm})`,
    md: `(min-width: ${size.md})`,
    lg: `(min-width: ${size.lg})`,
    xl: `(min-width: ${size.xl})`,
    xxl: `(min-width: ${size.xxl})`,
  },
  spacing: [
    '0rem',
    '0.25rem', // 4px
    '0.5rem', // 8px
    '0.75rem', // 12px
    '1rem', // 16px
    '1.25rem', // 20px
    '1.5rem', // 24px
    '1.75rem', // 28px
    '2rem', // 32px
  ],
  fontWeights,
}
export { theme, colors }
