// src/hooks/useFileUpload.ts

import { useState, useEffect, useRef } from 'react';

export interface FileStructure {
  name: string;
  isDirectory: boolean;
  content?: string;
  children?: FileStructure[];
  isNew?: boolean;
  isModified?: boolean;
}

const useFileUpload = (onComplete: (structure: FileStructure[]) => void) => {
  const [loadingProgress, setLoadingProgress] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rootFolderName, setRootFolderName] = useState<string | null>(null);
  const workerRef = useRef<Worker | null>(null);

  useEffect(() => {
    // Initialize the worker only once
    if (!workerRef.current) {
      workerRef.current = new Worker(new URL('../components/layout/buildFileStructureWorker.worker.ts', import.meta.url));
      
      workerRef.current.onmessage = (e) => {
        const data = e.data;
        if (data.type === 'progress') {
          setLoadingProgress(data.progress);
        } else if (data.type === 'complete') {
          const structure: FileStructure[] = data.structure;
          setLoadingProgress(100);
          setIsLoading(false);
          onComplete(structure);
          logAllFileContents(structure);
        }
      };
    }

    return () => {
      // Cleanup the worker when the hook is unmounted
      if (workerRef.current) {
        workerRef.current.terminate();
        workerRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpload = async (files: FileList | null, directoryHandle?: FileSystemDirectoryHandle) => {
    if (workerRef.current) {
      setLoadingProgress(0);
      setIsLoading(true);
      setRootFolderName(directoryHandle ? directoryHandle.name : "Uploaded Folder");

      if (directoryHandle) {
        // Send directoryHandle to the worker
        workerRef.current.postMessage({ directoryHandle });
      } else if (files) {
        // Convert FileList to array
        const filesArray = Array.from(files).map(file => ({
          name: file.name,
          webkitRelativePath: (file as any).webkitRelativePath || file.name,
          file: file,
        }));
        workerRef.current.postMessage({ filesArray });
      }
    }
  };

  const logAllFileContents = (structure: FileStructure[]) => {
    const fileContents: string[] = [];

    const traverse = (nodes: FileStructure[], path: string) => {
      nodes.forEach((node) => {
        const currentPath = path ? `${path}/${node.name}` : node.name;
        if (node.isDirectory && node.children) {
          traverse(node.children, currentPath);
        }
        if (node.content !== undefined && !node.isDirectory) {
          const fileContentString = `File: ${currentPath}\nContent:\n${node.content}\n\n`;
          fileContents.push(fileContentString);
        }
      });
    };

    traverse(structure, '');
    console.log(fileContents.join(''));
  };

  return { handleUpload, loadingProgress, isLoading, rootFolderName };
};

export default useFileUpload;
