import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { orange } from '@mui/material/colors';
import { FileStructure } from '../../hooks/useFileUpload';

interface FolderNodeProps {
  node: FileStructure;
  level: number;
  expandedFolders: { [key: string]: boolean };
  toggleFolder: (folderName: string) => void;
}

const FolderNode: React.FC<FolderNodeProps> = ({ node, level, expandedFolders, toggleFolder }) => {
  const isExpanded = expandedFolders[node.name];
  const hasChildren = node.isDirectory && node.children && node.children.length > 0;

  const highlightColor = node.isNew ? '#FB7EB9' : node.isModified ? orange[500] : '#A5A5A5';

  return (
    <Box
      pl={0} // No padding for this node itself
      display="flex"
      flexDirection="column"
      sx={{
        cursor: 'pointer',
        whiteSpace: 'nowrap',
        marginBottom: '2px',
        backgroundColor: 'transparent',
        position: 'relative',
        '&:before': {
          content: '""',
          position: 'absolute',
          left: '0',
          top: '0',
          bottom: '0',
          width: '1px',
          backgroundColor: '#32B3D2',
          display: level > 0 ? 'block' : 'none', // Only show line for nested levels
        },
      }}
    >
      <Typography
        color={node.isDirectory ? '#FFFFFF' : highlightColor}
        onClick={() => node.isDirectory ? toggleFolder(node.name) : undefined}
        sx={{
          display: 'flex',
          alignItems: 'center',
          '&:before': {
            content: '""',
            width: '15px',
            height: '1px',
            backgroundColor: '#32B3D2',
            marginRight: '8px',
            display: level > 0 ? 'block' : 'none', // Only show the horizontal line for nested levels
          },
        }}
      >
        {node.name}
        {node.isDirectory && (
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation(); // Prevent triggering the folder toggle
              toggleFolder(node.name);
            }}
            sx={{
              marginLeft: 'auto',
              padding: '4px',
            }}
          >
            <ArrowForwardIosIcon
              sx={{
                fontSize: "12px",
                transform: isExpanded ? 'rotate(90deg)' : 'rotate(0deg)',
                transition: 'transform 0.2s ease',
                color: 'white',
              }}
            />
          </IconButton>
        )}
        {node.isNew && (
          <Typography color="#FB7EB9" fontSize="0.75rem" ml={1}>
            New
          </Typography>
        )}
      </Typography>

      {isExpanded && hasChildren && (
        <Box pl={level === 0 ? 0 : 2}> {/* Padding is 0 for the first level, 2 for the rest */}
          {node.children!.map((child, index) => (
            <FolderNode
              key={index}
              node={child}
              level={level + 1}
              expandedFolders={expandedFolders}
              toggleFolder={toggleFolder}
            />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default FolderNode;
