import React, { useEffect, useState } from 'react';
import { Box, IconButton, styled } from '@mui/material';
import { Icons } from '../../assets/Icons'; // Import the icons from your Icons file
import { useNavigate, useLocation } from 'react-router-dom';

const VerticalMenu = () => {
  const navigate = useNavigate(); // Use react-router navigate function
  const location = useLocation(); // Track the current location
  const [selected, setSelected] = useState<string>('dashboard'); // Default to 'dashboard'

  // Define menu items
  const menuItems = [
    { name: 'dashboard', icon: Icons.homeIcon, path: '/dashboard' },
    { name: 'chat', icon: Icons.chatIcon, path: '/chat' },
    { name: 'pricing', icon: Icons.PlanIcon, path: '/pricing' },
  ];

  // Update selected state based on the current URL location
  useEffect(() => {
    const currentItem = menuItems.find((item) => location.pathname.startsWith(item.path));
    if (currentItem) {
      setSelected(currentItem.name);
    }
  }, [location.pathname]); // Run this effect whenever the location changes

  // Handle navigation and selection
  const handleNavigation = (path: string, name: string) => {
    setSelected(name); // Set selected state
    navigate(path); // Navigate to the page
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      bgcolor="#141517"
      height="60%"
    >
      <Box mt={2} display="flex" flexDirection="column" alignItems="center">
        {menuItems.map((item) => (
          <StyledIconButton
            key={item.name}
            isSelected={selected === item.name}
            onClick={() => handleNavigation(item.path, item.name)}
          >
            {item.icon({
              color: selected === item.name ? '#FB7EB9' : '#fff',
              size: 25,
            })}
          </StyledIconButton>
        ))}
      </Box>
    </Box>
  );
};

// Styled component for selected icon buttons
const StyledIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== 'isSelected', // Do not pass `isSelected` to IconButton directly
})<{ isSelected: boolean }>(({ isSelected }) => ({
  marginBottom: '20px',
  position: 'relative',
  '& svg': {
    width: '25px',
    height: '25px',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    height: '100%',
    width: '1.5px',
    backgroundColor: isSelected ? '#FB7EB9' : 'transparent',
    borderRadius: '0',
  },
}));

export default VerticalMenu;
