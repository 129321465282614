import React, { useEffect } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const PaymentSuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Optionally, fetch subscription details or notify the backend
  }, []);

  const handleGoToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      bgcolor="#141517"
      color="white"
    >
      <Typography variant="h4" gutterBottom>
        Payment Successful!
      </Typography>
      <Typography variant="body1" gutterBottom>
        Thank you for your purchase. Your subscription is now active.
      </Typography>
      <Button variant="contained" color="secondary" onClick={handleGoToDashboard}>
        Go to Dashboard
      </Button>
    </Box>
  );
};

export default PaymentSuccessPage;
