// src/pages/ChatPage.tsx

import React, { useState, useEffect } from 'react';
import { Box, Typography, Snackbar, LinearProgress, Alert, Link } from '@mui/material';
import ChatHeader from '../components/chat/ChatHeader';
import ChatInput from '../components/chat/ChatInput';
import ChatWelcome from '../components/chat/ChatWelcome';
import useChat, { Chat, Message } from '../hooks/useChat';
import { useParams, useNavigate } from 'react-router-dom';
import { useProjectsContext } from '../context/ProjectsContext';
import ChatMessages from '../components/chat/ChatMessages';
import ChatListBar from '../components/chat/ChatListBar';

const ChatPage = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();
  const { projects, loading: projectsLoading, error: projectsError } = useProjectsContext();
  const isDefault = !projectId || projectId === 'default';
  const numericProjectId = projectId ? Number(projectId) : undefined;

  const {
    chats,
    selectedChat,
    loading,
    error: chatError,
    sendMessage,
    attachFile,
    setSelectedChat,
    fetchChats,
    isBotLoading,
    cancelMessage,
    createNewChat,
    subscriptionLoading,
    queryStatus,
  } = useChat(numericProjectId);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<React.ReactNode>('');

  // Handle copying code to clipboard
  const handleCopyCode = (code: string) => {
    navigator.clipboard.writeText(code);
    setSnackbarMessage('Code copied to clipboard!');
    setSnackbarOpen(true);
  };

  // Close the snackbar
  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  // Handle sending a new message
// Inside the handleSendMessage function of ChatPage.tsx
const handleSendMessage = (messageContent: string) => {
  // Check if user has reached their limit before sending the message
  if (hasNoRemainingQueries) {
    // Set the snackbar state to show the warning message
    setSnackbarMessage(
      <>
        You have reached your monthly query limit.{' '}
        <Link href="/contact" color="inherit" underline="always" target="_blank" rel="noopener noreferrer">
          Contact us
        </Link>{' '}
        to upgrade your plan.
      </>
    );
    setSnackbarOpen(true); // Always open snackbar when limit is reached
    return; // Prevent sending the message
  }

  // Proceed with sending the message if limit is not reached
  sendMessage(messageContent);
};


  // Handle attaching a file
  const handleAttachFile = (file: File) => {
    attachFile(file);
  };

  // Handle selecting a chat from the sidebar
  const handleSelectChat = (chat: Chat) => {
    setSelectedChat(chat);
    // Do not navigate to /chat/<chat_id> to prevent misinterpretation
  };

  // useEffect to fetch chats or redirect
  useEffect(() => {
    const initializeChats = async () => {
      if (isDefault) {
        // If user navigates to /chat without ID and has projects, redirect to first project
        if (!projectsLoading) {
          if (projects.length > 0) {
            const firstProject = projects[0]; // You can sort projects if needed
            console.log('Redirecting to first project:', firstProject.id);
            navigate(`/chat/${firstProject.id}`, { replace: true });
          }
          // If no projects, do not fetch chats and allow rendering of welcome/upload
        }
        // Else, projects are still loading, do nothing
      } else if (numericProjectId && numericProjectId > 0) {
        if (!projectsLoading) { // Ensure projects are loaded
          const project = projects.find(p => p.id === numericProjectId);
          console.log('Found project:', project);
          if (!project) {
            console.warn(`Project with ID ${numericProjectId} not found.`);
            navigate('/not-found', { replace: true });
          } else {
            await fetchChats();
          }
        }
        // Else, projects are still loading, do nothing
      }
    };

    initializeChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, projects, projectsLoading]);

  // useEffect to set selectedChat when chats are updated
  useEffect(() => {
    const selectDefaultChat = () => {
      if (chats.length > 0) {
        if (isDefault) {
          const defaultChat = chats.find(chat => chat.title === 'Default Chat');
          if (defaultChat) {
            console.log('Selecting Default Chat:', defaultChat.id);
            setSelectedChat(defaultChat);
          } else {
            console.log('Selecting first available chat:', chats[0].id);
            setSelectedChat(chats[0]);
          }
        } else {
          console.log('Selecting first available chat for project:', chats[0].id);
          setSelectedChat(chats[0]);
        }
      } else {
        if (isDefault) {
          console.log('No existing chats. Creating a new default chat.');
          createNewChat();
        }
      }
    };

    selectDefaultChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chats]);

  // useEffect to handle errors from useChat
  useEffect(() => {
    if (chatError) {
      // Check if the error is about reaching the limit
      if (chatError.includes('monthly query limit')) {
        setSnackbarMessage(
          <>
            You have reached your monthly query limit.{' '}
            <Link href="/contact" color="inherit" underline="always">
              Contact us
            </Link>{' '}
            to upgrade your plan.
          </>
        );
        setSnackbarOpen(true);
      } else {
        // For other errors, show the error message
        setSnackbarMessage(chatError);
        setSnackbarOpen(true);
      }
    }
  }, [chatError]);

  // Determine if user has no remaining queries
  const hasNoRemainingQueries: boolean = !!(chatError && chatError.includes('monthly query limit'));

  // Optional: Prevent rendering until projects and subscription are loaded
  if (projectsLoading || subscriptionLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh" bgcolor="#141517">
        <Typography variant="h6" color="white">
          Loading...
        </Typography>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" height="100vh" overflow="hidden" width="100%" bgcolor="#141517">
      {/* Chat Header */}
      <ChatHeader createNewChat={createNewChat} />

      {/* Loading bar */}
      {(loading || isBotLoading) && (
        <LinearProgress variant="indeterminate" sx={{ position: 'fixed', top: 0, left: 0, right: 0 }} />
      )}

      <Box flexGrow={1} display="flex" flexDirection="row" p={0} overflow="hidden">
        {/* Chat Messages */}
        <Box flexGrow={1} display="flex" flexDirection="column" p={0} overflow="auto">
          {loading ? (
            <Typography color="white" pl={2} pt={2}>Loading...</Typography>
          ) : selectedChat && selectedChat.messages.length > 0 ? (
            <ChatMessages messages={selectedChat.messages} onCopyCode={handleCopyCode} isBotLoading={isBotLoading} />
          ) : (
            <ChatWelcome />
          )}
        </Box>

        {/* Chat List Bar */}
        <ChatListBar chats={chats} onSelectChat={handleSelectChat} />
      </Box>

      {/* Remaining Queries Indicator */}
      {/* {queryStatus && (
        <Box p={2} bgcolor="#2c2c2c">
          <Typography variant="body2" color="white">
            Remaining Queries: {queryStatus.remaining_queries}
          </Typography>
          <LinearProgress
            variant="determinate"
            value={(queryStatus.remaining_queries / 1000) * 100} 
            sx={{
              height: 10,
              borderRadius: 5,
              backgroundColor: '#555',
              '& .MuiLinearProgress-bar': {
                backgroundColor: queryStatus.remaining_queries === 0 ? '#f44336' : '#2196f3',
              },
            }}
          />
        </Box>
      )} */}

      {/* Chat Input */}
      <ChatInput
        onSendMessage={handleSendMessage}
        onAttachFile={handleAttachFile}
        isBotLoading={isBotLoading}
        onCancelMessage={cancelMessage}
        // No disabled prop
      />

      {/* Snackbar for notifications */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Centered position
        open={snackbarOpen}
        onClose={handleCloseSnackbar}
        autoHideDuration={6000}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={hasNoRemainingQueries ? 'warning' : 'error'}
          sx={{ 
            width: '100%', 
            backgroundColor: hasNoRemainingQueries ? '#ff9800' : '#f44336', 
            color: 'white', 
            fontWeight: 'bold' 
          }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ChatPage;
