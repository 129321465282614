// src/context/ProjectsContext.tsx

import React, { createContext, useContext } from 'react';
import useProjects, { Project, ProjectCreatePayload } from '../hooks/useProjects';

interface ProjectsContextType {
  projects: Project[];
  loading: boolean;
  error: string | null;
  addProject: (projectData: ProjectCreatePayload) => Promise<Project>;
  deleteProject: (projectId: number) => Promise<void>; // Added deleteProject to the interface
}

const ProjectsContext = createContext<ProjectsContextType | undefined>(undefined);

export const ProjectsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { projects, loading, error, addProject, deleteProject } = useProjects();

  return (
    <ProjectsContext.Provider value={{ projects, loading, error, addProject, deleteProject }}>
      {children}
    </ProjectsContext.Provider>
  );
};

export const useProjectsContext = (): ProjectsContextType => {
  const context = useContext(ProjectsContext);
  if (!context) {
    throw new Error('useProjectsContext must be used within a ProjectsProvider');
  }
  return context;
};
