import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { ClerkProvider } from '@clerk/clerk-react';
import { BrowserRouter as Router } from 'react-router-dom';
import ErrorBoundary from './components/ErrorBoundary';
import { ProjectsProvider } from './context/ProjectsContext';

const clerkPublishableKey = process.env.REACT_APP_CLERK_FRONTEND_API!;

const initializePaddle = () => {
  if (window.Paddle) {
    // window.Paddle.Environment.set('live'); 
  }
};

const Root = () => {
  useEffect(() => {
    initializePaddle();
  }, []);

  return (
    <ClerkProvider publishableKey={clerkPublishableKey}>
      <Router>
        <ProjectsProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </ProjectsProvider>
      </Router>
    </ClerkProvider>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>
);
