// src/hooks/useAxios.ts
import { useMemo } from 'react';
import axios, { AxiosInstance } from 'axios';
import useAuth from './useAuth';

const useAxios = (): AxiosInstance => {
  const { getToken } = useAuth();

  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: process.env.REACT_APP_BACKEND_URL || 'https://codura-backend-production.up.railway.app/api/',
    });

    instance.interceptors.request.use(
      async (config) => {
        try {
          // Use the custom token template 'id_token'
          const token = await getToken({ template: 'auth' }); // Ensure 'id_token' matches your template name
          // console.log(`Intercepting ${config.method?.toUpperCase()} request to ${config.url}`);
          // console.log('Retrieved token:', token);
          if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            // console.log('Authorization header set:', config.headers.Authorization);
          }
        } catch (error) {
          console.error('Error fetching Clerk token:', error);
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    return instance;
  }, [getToken]);

  return axiosInstance;
};

export default useAxios;
