import { useState, useEffect } from 'react';
import useAxios from './useAxios';

export interface SubscriptionData {
  id: number;
  user: number;
  plan: string;
  billing_cycle: string;
  status: string;
  trial_ends_at?: string;
  // Add other relevant fields as needed
}

export interface QueryStatusData {
  remaining_queries: number;
}

const SUBSCRIPTION_STORAGE_KEY = 'subscriptionData';
const QUERY_STATUS_STORAGE_KEY = 'queryStatusData';

const useSubscriptionData = () => {
  const axiosInstance = useAxios();
  const [subscription, setSubscription] = useState<SubscriptionData | null>(null);
  const [queryStatus, setQueryStatus] = useState<QueryStatusData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  // Fetch subscription data from API
  const fetchSubscription = async () => {
    try {
      const response = await axiosInstance.get<SubscriptionData>('subscription/');
      setSubscription(response.data);
      // Save to localStorage (optional: remove this if you do not want to store it locally)
      localStorage.setItem(SUBSCRIPTION_STORAGE_KEY, JSON.stringify(response.data));
    } catch (err: any) {
      console.error('Error fetching subscription:', err);
      setError('Failed to fetch subscription data.');
    }
  };

  // Fetch query status data from API
  const fetchQueryStatus = async () => {
    try {
      const response = await axiosInstance.get<QueryStatusData>('query-status/');
      setQueryStatus(response.data);
      // Save to localStorage
      localStorage.setItem(QUERY_STATUS_STORAGE_KEY, JSON.stringify(response.data));
    } catch (err: any) {
      console.error('Error fetching query status:', err);
      setError('Failed to fetch query status.');
      // Try to load from localStorage if API call fails
      const storedQueryStatus = localStorage.getItem(QUERY_STATUS_STORAGE_KEY);
      if (storedQueryStatus) {
        setQueryStatus(JSON.parse(storedQueryStatus));
        setError(null); // Clear error if data is loaded from localStorage
      }
    }
  };

  // Load subscription and query status data from localStorage or fetch from API
  useEffect(() => {
    const loadData = async () => {
      // Load subscription data from API every time
      await fetchSubscription();

      // Load query status data from localStorage if available
      const storedQueryStatus = localStorage.getItem(QUERY_STATUS_STORAGE_KEY);
      if (storedQueryStatus) {
        setQueryStatus(JSON.parse(storedQueryStatus));
      }

      // Always fetch the latest query status from the API
      await fetchQueryStatus();

      setLoading(false);
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isTrial = subscription?.status === 'trialing';

  const decrementRemainingQueries = () => {
    if (queryStatus && queryStatus.remaining_queries > 0) {
      const updatedQueryStatus: QueryStatusData = {
        remaining_queries: queryStatus.remaining_queries - 1,
      };
      setQueryStatus(updatedQueryStatus);
      localStorage.setItem(QUERY_STATUS_STORAGE_KEY, JSON.stringify(updatedQueryStatus));
    }
  };

  const hasRemainingQueries = (): boolean => {
    if (!queryStatus) return false;
    return queryStatus.remaining_queries > 0;
  };

  return {
    subscription: isTrial
      ? { ...subscription, plan: `${subscription?.plan} trial` }
      : subscription,
    queryStatus,
    loading,
    error,
    fetchSubscription, // To manually refetch data if needed
    fetchQueryStatus,  // To manually refetch query status if needed
    decrementRemainingQueries, // To decrement queries remaining locally
    hasRemainingQueries, // To check if there are remaining queries
  };
};

export default useSubscriptionData;
