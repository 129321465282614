// /codura/src/components/chat/ChatMessages.tsx

import React, { useEffect, useRef } from 'react';
import { Box, Typography, IconButton, Skeleton } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { atomDark } from 'react-syntax-highlighter/dist/esm/styles/prism'; // Changed theme to Atom One Dark
import ReactMarkdown, { Components } from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface Message {
  id: number;
  chat: number;
  sender: string | null; // 'user' or 'codura' (null for bot)
  content: string;
  timestamp: string;
  is_bot: boolean;
  type: 'text' | 'file' | 'code' | 'mixed';
}

interface ChatMessagesProps {
  messages: Message[];
  onCopyCode: (code: string) => void;
  isBotLoading: boolean;
}

const ChatMessages: React.FC<ChatMessagesProps> = ({ messages, onCopyCode, isBotLoading }) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // Scroll to the bottom when the component mounts or messages update
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages, isBotLoading]);

  const components: Partial<Components> = {
    // Define the 'code' component without strict typing to avoid TypeScript errors
    code: ({ inline, className, children, ...props }: any) => {
      const match = /language-(\w+)/.exec(className || '');
      if (!inline && match) {
        return (
          <Box position="relative" my={2}>
            <SyntaxHighlighter
              style={atomDark} // Use Atom One Dark theme
              language={match[1]}
              PreTag="div"
              customStyle={{
                borderRadius: '8px',
                padding: '16px',
                backgroundColor: '#1f1f20', // Ensures the code block has a distinct dark background
                margin: 0, // Remove default margin to prevent spacing issues
                fontSize: '0.85em',
              }}
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
            <IconButton
              size="small" // Make the copy icon smaller
              aria-label="Copy code"
              onClick={() => onCopyCode(String(children).replace(/\n$/, ''))}
              sx={{
                position: 'absolute',
                top: '8px',
                right: '8px',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.7)',
                },
              }}
            >
              <ContentCopyIcon fontSize="small" />
            </IconButton>
          </Box>
        );
      } else {
        return <code className={className} {...props}>{children}</code>;
      }
    },
    // Define the 'a' component to handle links
    a: ({ node, ...props }: any) => <a {...props} target="_blank" rel="noopener noreferrer" />,
  };

  const renderContent = (msg: Message) => {
    if (msg.type === 'file') {
      return (
        <Box
          bgcolor="#2A2A2A"
          p={2}
          borderRadius="8px"
          maxWidth="75%"
        >
          <Typography variant="body2" color="white">
            File: {msg.content}
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        px={2}
        maxWidth="75%"
        sx={{
          bgcolor: msg.is_bot ? '#2A2A2A' : '#333',
          color: 'white',
          borderRadius: '10px',
          wordWrap: 'break-word',
        }}
      >
        <ReactMarkdown
          children={msg.content}
          remarkPlugins={[remarkGfm]}
          components={components}
        />
      </Box>
    );
  };

  return (
    <Box flexGrow={1} p={2} overflow="auto" position="relative">
      {messages.length === 0 ? (
        <Typography variant="body1" color="gray" textAlign="center" mt={3}>
          No messages yet. Start a conversation!
        </Typography>
      ) : (
        messages.map((msg) => (
          <Box
            key={msg.id}
            mb={2}
            display="flex"
            justifyContent={msg.is_bot ? 'flex-start' : 'flex-end'}
            alignItems="flex-start"
          >
            {renderContent(msg)}
          </Box>
        ))
      )}

      {/* Loading skeleton for bot response */}
      {isBotLoading && (
        <Box
          mb={2}
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Box
            p={2}
            maxWidth="75%"
            width="85%"
            sx={{
              bgcolor: '#2A2A2A',
              color: 'white',
              borderRadius: '10px',
              wordWrap: 'break-word',
            }}
          >
            <Skeleton variant="text" width="90%" />
            <Skeleton variant="text" width="75%" />
            <Skeleton variant="rectangular" height={20} width="100%" />
          </Box>
        </Box>
      )}
      <div ref={messagesEndRef} />
    </Box>
  );
};

export default ChatMessages;
