// src/hooks/useProjects.ts

import { useState, useEffect } from 'react';
import useAxios from './useAxios';

export interface FileStructure {
  name: string;
  isDirectory: boolean;
  content?: string;
  children?: FileStructure[];
  isNew?: boolean;
  isModified?: boolean;
}

export interface Project {
  id: number;
  name: string;
  description?: string;
  content?: string;
  structure: FileStructure[] | null;
  created_at: string;
  user: number;
}

export interface ProjectCreatePayload {
  name: string;
  content: string;
  description?: string;
  structure: FileStructure[];
}

const useProjects = () => {
  const axiosInstance = useAxios();
  const [projects, setProjects] = useState<Project[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProjects = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.get<Project[]>('projects/');
        setProjects(response.data);
      } catch (err: any) {
        console.error('Error fetching projects:', err);
        setError('Failed to fetch projects.');
      } finally {
        setLoading(false);
      }
    };
    fetchProjects();
  }, [axiosInstance]);

  const addProject = async (projectData: ProjectCreatePayload): Promise<Project> => {
    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.post<Project>('projects/upload/', projectData);
      setProjects((prev) => [...prev, response.data]);
      return response.data;
    } catch (err: any) {
      console.error('Error uploading project:', err);
      setError('Failed to upload project.');
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const deleteProject = async (projectId: number): Promise<void> => {
    try {
      await axiosInstance.delete(`projects/${projectId}/`);
      setProjects((prev) => prev.filter((project) => project.id !== projectId));
    } catch (err) {
      console.error('Error deleting project:', err);
      throw err;
    }
  };

  return { projects, loading, error, addProject, deleteProject };
};

export default useProjects;
