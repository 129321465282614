import React from 'react';
import { Box, Typography, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckIcon from '../../assets/media/check.svg';

interface PricingCardProps {
  title: string;
  price: string;
  yearlyTotal?: string;
  description: string;
  features: string[];
  buttonText: string;
  highlight: boolean;
  onClick?: () => void;
  disabled?: boolean;  // Added prop
}

const PricingCard: React.FC<PricingCardProps> = ({ title, price, yearlyTotal, description, features, buttonText, highlight, onClick, disabled }) => {
  return (
    <Box
      border={highlight ? '2px solid #FB7EB9' : '1px solid #333'}
      borderRadius="8px"
      padding="20px"
      bgcolor={disabled ? '#333' : '#1D1E22'}  // Adjusted for disabled state
      color="white"
      minHeight="450px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        opacity: disabled ? 0.4 : 1,  // Adjusted for disabled state
      }}
    >
      <Box>
        <Typography variant="h6" fontWeight="bold" mb={1}>
          {title}
        </Typography>
        <Typography variant="body2" color="#A5A5A5" mb={2}>
          {description}
        </Typography>
        <Typography variant="h3" fontWeight="normal">
          {price}
        </Typography>
        {yearlyTotal && (
          <Typography variant="body1" color="#A5A5A5" mt={0.4}>
            {yearlyTotal}
          </Typography>
        )}

        {/* Features List */}
        <List>
          {features.map((feature, index) => (
            <ListItem key={index} sx={{ pb: '0', px: '0', alignItems: 'start' }}>
              <ListItemIcon sx={{ minWidth: '30px', mt: '5px' }}>
                <img src={CheckIcon} alt="check icon" />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2" component="span">
                  <strong style={{ fontSize: '1rem' }}>{feature.split(':')[0]}:</strong> {feature.split(':')[1]}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
        </List>
      </Box>

      <Button 
        variant="contained" 
        sx={{ borderRadius: '50px', mt: '10px', padding: '10px 20px', bgcolor: disabled ? 'grey' : 'white', textTransform: 'none', fontSize: '1.1rem' }}
        onClick={onClick}
        disabled={disabled}
      >
        {buttonText}
      </Button>
    </Box>
  );
};

export default PricingCard;
