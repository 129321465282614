import React from 'react';
import { Box, Typography } from '@mui/material';
import logo from "../../assets/media/logoicon.svg"
import feature1 from "../../assets/media/feature1.svg"
import feature2 from "../../assets/media/feature2.svg"
import feature3 from "../../assets/media/feature3.svg"
import feature4 from "../../assets/media/feature4.svg"

const ChatWelcome = () => {
  return (
    <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Box mb={2}>
        <img src={logo} alt="Loader" style={{ width: '60px' }} />
      </Box>
      <Typography variant="h4" color="white" gutterBottom style={{ fontWeight: '600' }} >
        Welcome to Codura
      </Typography>
      <Typography variant="body1" color="gray" align="center" sx={{ maxWidth: '600px', mt: 2 }}>
      Elevate your development workflow with Codura, the ultimate platform for AI-assisted coding and project management.
      </Typography>

      <Box display="flex" justifyContent="center" mt={3} >
        <Box display="flex" justifyContent="start" alignItems="start" flexDirection='column' >
          <Box mx={3} display="flex" columnGap={1} justifyContent="center" alignItems="center" mb={1}>
            <img src={feature1} alt="Loader" style={{ width: '20px' }} />
            <Typography variant="subtitle1" color="white">
              Seamless Integrations
            </Typography>
          </Box>
          <Box mx={3} display="flex" columnGap={1} justifyContent="center" alignItems="center">
            <img src={feature2} alt="Loader" style={{ width: '20px' }} />
            <Typography variant="subtitle1" color="white">
            Knowladge Base
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="start" alignItems="start" flexDirection='column'>
          <Box mx={3} display="flex" columnGap={1} justifyContent="center" alignItems="center" mb={1}>
            <img src={feature3} alt="Loader" style={{ width: '20px' }} />
            <Typography variant="subtitle1" color="white">
            Natural Language Conversation
            </Typography>
          </Box>
          <Box mx={3} display="flex" columnGap={1} justifyContent="center" alignItems="center">
            <img src={feature4} alt="Loader" style={{ width: '20px' }} />
            <Typography variant="subtitle1" color="white">
            Personolized Recomendations
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatWelcome;
