// src/components/layout/SharedLayout.tsx

import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Box, IconButton, Drawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import VerticalMenu from './VerticalMenu';
import SidebarContent from './SidebarContent';
import logo from "../../assets/media/logo.svg";
import { useProjectsContext } from '../../context/ProjectsContext';
import { Project } from '../../hooks/useProjects'; // Added import

const SharedLayout = () => {
  const location = useLocation();
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { projects, loading: projectsLoading } = useProjectsContext();

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  // Determine if current route is /chat or /chat/:projectId
  const isChatRoute = location.pathname.startsWith('/chat');

  // Extract projectId from URL if in /chat/:projectId
  const pathSegments = location.pathname.split('/');
  let currentProject: Project | null = null;
  if (isChatRoute && pathSegments[2]) {
    const projectId = Number(pathSegments[2]);
    currentProject = projects.find(p => p.id === projectId) || null;
  }

  return (
    <Box display="flex" height="100vh" width="100vw">
      {/* Logo, hidden on mobile screens */}
      <Box
        component="img"
        src={logo}
        alt="Codura Logo"
        sx={{
          width: '140px',
          position: 'absolute',
          top: '20px',
          left: '20px',
          '@media (max-width: 900px)': {
            display: 'none',
          },
        }}
      />

      {/* Hamburger Menu for Mobile */}
      <IconButton
        onClick={toggleMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
          position: 'absolute',
          top: '20px',
          left: '20px',
          zIndex: 10,
        }}
      >
        <MenuIcon sx={{ color: 'white' }} />
      </IconButton>

      {/* Drawer (for Hamburger Menu on mobile) */}
      <Drawer
        anchor="left"
        open={isMenuOpen}
        onClose={toggleMenu}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            bgcolor: '#141517',
            display: 'flex',
            flexDirection: 'row',
            width: isChatRoute ? '80vw' : '20vw',
          },
        }}
      >
        {/* VerticalMenu takes up 20vw */}
        <Box width="20vw">
          <VerticalMenu />
        </Box>

        {/* SidebarContent takes up the remaining 60vw on mobile but only for the chat page */}
        {isChatRoute && (
          <Box
            width="60vw"
            borderLeft="1px solid #333"
            height="100%"
            bgcolor="#141517"
          >
            <SidebarContent project={currentProject} />
          </Box>
        )}
      </Drawer>

      {/* Vertical Menu for Desktop */}
      <Box
        display={{ xs: 'none', md: 'flex' }}
        flexDirection="column"
        bgcolor="#141517"
        width="4vw"
        minWidth="40px"
        height="100vh"
      >
        <VerticalMenu />
      </Box>

      {/* Sidebar for Chat Page on Desktop */}
      {isChatRoute && (
        <Box display={{ xs: 'none', md: 'flex' }} flexDirection="row">
          {/* SidebarContent for Desktop */}
          <Box
            display={{ xs: 'none', md: 'flex' }}
            width="18vw"
            borderRight="1px solid #333"
            height="100%"
          >
            <SidebarContent project={currentProject} />
          </Box>
        </Box>
      )}

      {/* Main Page Content */}
      <Box
        flexGrow={1}
        bgcolor="#141517"
        display="flex"
        flexDirection="column"
        height="100vh"
        overflow="auto"
        width={
          isChatRoute
            ? { xs: '20vw', md: '76vw' }
            : { xs: '100vw', md: '94vw' }
        }
      >
        <Outlet />
      </Box>
    </Box>
  );
};

export default SharedLayout;
