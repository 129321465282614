import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import {
  SignedIn,
  SignedOut,
} from '@clerk/clerk-react';
import SharedLayout from './components/layout/SharedLayout';
import DashboardPage from './pages/DashboardPage';
import ChatPage from './pages/ChatPage';
import PricingPage from './pages/PricingPage';
import SignInPage from './pages/SignInPage';
import ExternalPage from './pages/ExternalPage';
import SignUpPage from './pages/SignUpPage';
import PaymentSuccessPage from './pages/PaymentSuccessPage';
import PaymentCancelPage from './pages/PaymentCancelPage';
import ProtectedRoute from './components/ProtectedRoute';
import { CssBaseline, createTheme } from '@mui/material';
import SyncUser from './components/SyncUser';
import NotFoundPage from './pages/NotFoundPage';

const customTheme = createTheme({
  ...theme,
  palette: {
    ...theme.palette,
    background: {
      default: '#141517',
      paper: '#1E1E1E',
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <SignedIn>
        <SyncUser />
        <Routes>
          {/* Protected Routes with SharedLayout */}
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <SharedLayout />
              </ProtectedRoute>
            }
          >
            {/* Redirect root to dashboard */}
            <Route index element={<Navigate to="/dashboard" replace />} />

            {/* Dashboard Route */}
            <Route path="dashboard" element={<DashboardPage />} />

            {/* Chat Routes */}
            <Route path="chat" element={<ChatPage />} />
            <Route path="chat/:projectId" element={<ChatPage />} />

            {/* Pricing Route */}
            <Route path="pricing" element={<PricingPage />} />

            {/* Fallback for any unmatched routes within Protected Routes */}
            <Route path="*" element={<NotFoundPage />} />
          </Route>

          {/* Public Routes Accessible for Signed-In Users (No SharedLayout) */}
          <Route path="/contact" element={<ExternalPage page="contact" />} />
          <Route path="/terms-and-conditions" element={<ExternalPage page="terms-and-conditions" />} />
          <Route path="/policy" element={<ExternalPage page="policy" />} />
          
          {/* Redirect unmatched signed-in routes */}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </SignedIn>
      <SignedOut>
        <Routes>
          {/* Public Routes */}
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="/payment-success" element={<PaymentSuccessPage />} />
          <Route path="/payment-cancel" element={<PaymentCancelPage />} />
          <Route path="/contact" element={<ExternalPage page="contact" />} />
          <Route path="/terms-and-conditions" element={<ExternalPage page="terms-and-conditions" />} />
          <Route path="/policy" element={<ExternalPage page="policy" />} />
          <Route path="/" element={<ExternalPage page="index" />} />
          {/* Redirect any other public routes to sign-in */}
          <Route path="*" element={<Navigate to="/sign-in" replace />} />
        </Routes>
      </SignedOut>
    </ThemeProvider>
  );
};

export default App;
