// src/components/ProtectedRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';

interface ProtectedRouteProps {
  children: JSX.Element;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isLoaded, isSignedIn } = useUser();

  if (!isLoaded) {
    // Optionally, return a loading spinner or placeholder
    return null;
  }

  return isSignedIn ? children : <Navigate to="/sign-in" replace />;
};

export default ProtectedRoute;
