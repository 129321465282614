import React, { useEffect } from 'react';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';

const SyncUser = () => {
  const { isLoaded, isSignedIn, user, getToken } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const syncUser = async () => {
      if (isLoaded && isSignedIn && user) {
        try {
          const token = await getToken({ template: 'auth' });
          await axios.post(
            `${process.env.REACT_APP_BACKEND_URL}auth/sync/`,
            { email: user.emailAddresses[0].emailAddress },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
        } catch (error) {
          console.error('Error syncing user with backend:', error);
          // Optionally, navigate to a page prompting the user to try again
          // navigate('/error');
        }
      }
    };

    syncUser();
  }, [isLoaded, isSignedIn, user, getToken, navigate]);

  return null;
};

export default SyncUser;
