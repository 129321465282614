// src/pages/DashboardPage.tsx

import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Link, LinearProgress, Snackbar } from '@mui/material';
import ProjectCard from '../components/dashboard/ProjectCard';
import UploadModal from '../components/modal/UploadModal';
import { colors } from '../config/theme';
import { UserButton } from '@clerk/clerk-react';
import useAuth from '../hooks/useAuth';
import useFileUpload, { FileStructure } from '../hooks/useFileUpload';
import { useProjectsContext } from '../context/ProjectsContext';
import { useNavigate } from 'react-router-dom';
import useSubscriptionData from '../hooks/useSubscriptionData';

const DashboardPage = () => {
  const [openModal, setOpenModal] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { isLoaded, user } = useAuth();
  const { projects, loading, error, addProject, deleteProject } = useProjectsContext();
  const navigate = useNavigate();
  const { subscription, loading: subscriptionLoading } = useSubscriptionData();

  useEffect(() => {
    if (user) {
      const script = document.createElement('script');
      script.src = 'https://conversations-widget.brevo.com/brevo-conversations.js';
      script.async = true;

      (window as any).BrevoConversationsID = '65be30975c5ea73baf735118';
      (window as any).BrevoConversations = (window as any).BrevoConversations || function () {
        ((window as any).BrevoConversations.q = (window as any).BrevoConversations.q || []).push(arguments);
      };

      (window as any).BrevoConversations('setUser', {
        name: user.firstName || 'Guest',
        email: user.emailAddresses[0]?.emailAddress || '',
      });

      document.head.appendChild(script);

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          mutation.addedNodes.forEach((node) => {
            if (node instanceof HTMLElement && node.id.startsWith('brevo')) {
              node.setAttribute('data-brevo-widget', 'true');
            }
          });
        });
      });

      observer.observe(document.body, { childList: true, subtree: true });

      return () => {
        document.head.removeChild(script);

        document.querySelectorAll('[data-brevo-widget]').forEach((widget) => {
          widget.remove();
        });

        observer.disconnect();

        delete (window as any).BrevoConversationsID;
        delete (window as any).BrevoConversations;
      };
    }
  }, [user]);

  const getProjectLimit = (plan: string) => {
    if (plan === 'starter') return 1;
    if (plan === 'pro') return 3;
    if (plan === 'business') return 10;
    return 0;
  };

  const handleUploadComplete = async (structure: FileStructure[]) => {
    if (!user) return;

    const folderName = structure.length > 0 && structure[0].isDirectory ? structure[0].name : 'Project';
    let aggregatedContent = '';

    const traverseAndAggregate = (nodes: FileStructure[], parentPath: string) => {
      nodes.forEach((node) => {
        const currentPath = parentPath ? `${parentPath}/${node.name}` : node.name;
        if (node.content) {
          aggregatedContent += `\n\n### ${currentPath}\n\n${node.content}`;
        }
        if (node.children) {
          traverseAndAggregate(node.children, currentPath);
        }
      });
    };

    traverseAndAggregate(structure, '');

    const traverseAndClean = (nodes: FileStructure[], parentPath: string): FileStructure[] => {
      return nodes.map((node) => {
        const currentPath = parentPath ? `${parentPath}/${node.name}` : node.name;
        if (node.isDirectory && node.children) {
          return {
            name: currentPath,
            isDirectory: true,
            children: traverseAndClean(node.children, currentPath),
          };
        } else {
          return {
            name: currentPath,
            isDirectory: false,
          };
        }
      });
    };

    const cleanStructure = traverseAndClean(structure, '');

    try {
      const newProject = await addProject({
        name: folderName,
        content: aggregatedContent,
        description: '',
        structure: cleanStructure,
      });
      handleCloseModal();
      navigate(`/chat/${newProject.id}`);
    } catch (err) {
      console.error('Error uploading project:', err);
    }
  };

  const { handleUpload, loadingProgress, isLoading } = useFileUpload(handleUploadComplete);

  const handleOpenModal = () => {
    if (subscription && !subscriptionLoading) {
      const projectLimit = getProjectLimit(subscription.plan);
      if (projects.length >= projectLimit) {
        setSnackbarOpen(true);
      } else {
        setOpenModal(true);
      }
    } else {
      const projectLimit = 0;
      if (projects.length >= projectLimit) {
        setSnackbarOpen(true);
      } else {
        setOpenModal(true);
      }
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDeleteProject = async (projectId: number) => {
    try {
      await deleteProject(projectId);
    } catch (err) {
      console.error('Error deleting project:', err);
    }
  };

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      p={4}
      mt={8}
      color="white"
      display="flex"
      flexDirection="column"
      justifyContent={'space-between'}
      height="100vh"
    >
      {isLoading && (
        <LinearProgress variant="determinate" value={loadingProgress} sx={{ position: 'fixed', top: 0, left: 0, right: 0 }} />
      )}
      <Box display="flex" flexDirection="column" justifyContent={'space-between'} height="100%">
        <Box>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={4}>
            <Typography variant="h5" fontWeight="bold">
              Hello {user ? `${user.firstName}!` : 'User!'}
            </Typography>
            <UserButton
              afterSignOutUrl="/"
              appearance={{
                elements: {
                  userButtonAvatarBox: {
                    width: '40px',
                    height: '40px',
                  },
                },
              }}
            />
          </Box>

          <Grid container spacing={3}>
            {loading && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}
            {projects.map((project) => (
              <Grid item xs={6} sm={4} md={2} key={project.id}>
                <ProjectCard project={project} onDelete={() => handleDeleteProject(project.id)} />
              </Grid>
            ))}
            <Grid item xs={6} sm={4} md={2}>
              <ProjectCard
                project={{ id: -1, name: 'Add Project', description: '', structure: null }}
                onAddProjectClick={handleOpenModal}
              />
            </Grid>
          </Grid>
          {error && (
            <Typography variant="body2" color="red" mt={2}>
              {error}
            </Typography>
          )}
        </Box>
        <Typography variant="body1" color="gray" mx={2} textAlign={'center'}>
          You can clone the repository from your GitHub or upload the folder manually
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center" mt={6} color="#A5A5A5">
        <Link href="/contact" variant="body2" mx={2} color={colors.brandGray400}>
          Contact
        </Link>
        <Link href="/policy" variant="body2" mx={2} color={colors.brandGray400}>
          Privacy & Policy
        </Link>
        <Link href="/terms-and-conditions" variant="body2" mx={2} color={colors.brandGray400}>
          Terms & conditions
        </Link>
      </Box>

      <UploadModal open={openModal} onClose={handleCloseModal} onUpload={handleUpload} />

      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        message={`You have reached the maximum number of projects for your plan. Please upgrade your plan to add more projects.`}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={4000}
      />
    </Box>
  );
};

export default DashboardPage;
