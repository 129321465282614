// src/pages/SignInPage.tsx
import React from 'react';
import { SignIn } from '@clerk/clerk-react';
import { Box } from '@mui/material';
import signupBg from '../assets/media/signinbg.svg';

const SignInPage = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100vh"
      sx={{
        backgroundImage: `url(${signupBg})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <SignIn
        path="/sign-in"
        routing="path"
        signUpUrl="/sign-up"
        afterSignInUrl="/dashboard" // Keep your desired redirect path
      />
    </Box>
  );
};

export default SignInPage;
