// src/pages/PricingPage.tsx

import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Switch, Link } from '@mui/material';
import PricingCard from '../components/price/PricingCard';
import { colors } from '../config/theme';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useAuth from '../hooks/useAuth';
import useSubscriptionData from '../hooks/useSubscriptionData';

const PricingPage = () => {
  const [billingCycle, setBillingCycle] = useState<'monthly' | 'yearly'>('yearly'); // Default to yearly billing
  const [paddleLoaded, setPaddleLoaded] = useState(false); // Ensure Paddle is loaded
  const { getToken, user } = useAuth(); // To access the logged-in user details
  const navigate = useNavigate();
  const { subscription, loading: subscriptionLoading, error: subscriptionError } = useSubscriptionData();

  const isSubscribed = !!subscription && subscription.status === 'active';
  const isTrial = !!subscription && subscription.status === 'trialing';

  const handleBillingCycleChange = () => {
    setBillingCycle(billingCycle === 'monthly' ? 'yearly' : 'monthly');
  };

  const calculatePrice = (yearlyPrice: number) => {
    if (billingCycle === 'monthly') {
      return (yearlyPrice * 1.33335).toFixed(2); // 15% added for monthly
    }
    return yearlyPrice.toFixed(2);
  };

  const features = [
    'Projects: 1 active project.',
    'File Upload Limit: Maximum of 100 files per project.',
    'AI Interactions: Up to 500 AI queries per month For the o1 model.',
    'Collaboration: Single-user only.',
    'Support: Standard email support.',
  ];

  const features2 = [
    'Projects: Up to 3 active projects.',
    'File Upload Limit: Maximum of 200,000 files per project.',
    'AI Interactions: Up to 2000 AI queries per month.',
    'Collaboration: Single-user only.',
    'Support: 24/7 dedicated support.',
  ];

  const features3 = [
    'Projects: Up to 10 active projects.',
    'File Upload Limit: Unlimited file uploads per project.',
    'AI Interactions: Unlimited AI queries.',
    'Collaboration: Up to 10 team members with full collaboration tools.',
    'Support: 24/7 dedicated support.',
  ];

  // Backend notification logic after successful payment
  const notifyBackend = async () => {
    if (user) {
      try {
        const token = await getToken(); // Get the user token
        await axios.post(
          '/api/payment-success/', // Backend API to notify
          { userId: user.emailAddresses[0].emailAddress }, // Pass user data
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } catch (error) {
        console.error('Error notifying backend:', error);
      }
    }
  };

  // Handle subscription initiation with Paddle's Checkout using priceId and quantity
  const handleStartSubscription = (plan: string) => {
    if (!paddleLoaded) {
      console.error('Paddle has not been loaded');
      return;
    }

    const product_id = plan === 'starter'
      ? billingCycle === 'yearly'
        ? 'pri_01j94eq33eabwhqyzdmev2cjwz'
        : 'pri_01j9s83pps2bgkkywrffj1gv82'
      : plan === 'pro'
        ? billingCycle === 'yearly'
          ? 'pri_01j94evmc4j83v0sqjwsyf107g'
          : 'pri_01j9s85e127brwv5y6fnatt7vy'
        : billingCycle === 'yearly'
          ? 'pri_01j94exh91qpt97gd7qkrcg4nh'
          : 'pri_01j9s878aqvfyr50bjqcxnq05t'; // Business plan

    const customData = {
      user_id: user?.emailAddresses[0].emailAddress, // Pass user email
      plan,
      billing_cycle: billingCycle,
    };

    const itemsList = [{
      priceId: product_id,
      quantity: 1,
    }];
    (window.Paddle.Checkout.open as any)({
      items: itemsList,
      customData, // Use customData instead of passthrough
      customer: {
        email: user?.emailAddresses[0].emailAddress, // Ensure email is passed
      },
      settings: {
        successUrl: 'https://www.codura.cc/dashboard',
      },
      successCallback: () => {
        notifyBackend(); // Trigger backend notification on success
        navigate('/dashboard');
      },
    });
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cdn.paddle.com/paddle/v2/paddle.js';
    script.async = true;
    script.onload = () => {
      if (window.Paddle && typeof window.Paddle.Initialize === 'function') {
        window.Paddle.Initialize({
          token: 'live_fea3cafc60c63ae3e5103a3ce9d',
          pwCustomer: {
            email: user?.emailAddresses[0].emailAddress || null
          }
        } as any); 
        setPaddleLoaded(true); // Mark Paddle as loaded
      } else {
        console.error('Paddle.Initialize is not available. Please ensure the script is loaded correctly.');
      }
    };
    script.onerror = () => {
      console.error('Failed to load Paddle.js');
    };
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box
      p={{ xs: 3, md: 4 }}
      color="white"
      mt={6}
      display="flex"
      justifyContent="space-between"
      flexDirection="column"
      height="100vh"
      sx={{ overflowY: 'scroll' }}
    >
      <Box>
        <Box>
          <Box display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
            <Box>
              <Typography variant="h4" fontWeight="bold" mb={1}>
                Transparent Pricing
              </Typography>
              <Typography variant="body1" color="#A5A5A5">
                Plans are available for every developer!
              </Typography>
            </Box>
            {subscription && !subscriptionLoading && (
              <Typography variant="body1" fontWeight="500" mb={1} color="#fff" >
                You are currently on the <Typography component="span" sx={{ textDecoration: 'underline' }} variant="body1" fontWeight="500" mb={1} color="#FF7DB8">{subscription.plan.charAt(0).toUpperCase() + subscription.plan.slice(1)} plan.</Typography>
              </Typography>
            )}
          </Box>
          <Box display="flex" justifyContent="flex-end" alignItems="center" mb={2}>
            <Typography variant="body2" color="white" mr={2}>
              Billed Monthly
            </Typography>
            <Switch
              checked={billingCycle === 'yearly'}
              onChange={handleBillingCycleChange}
              color="secondary"
            />
            <Typography variant="body2" color="#32B3D2" ml={2}>
              Billed Yearly (save 25%)
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={3} justifyContent="center">
          {/* Starter Plan */}
          <Grid item xs={12} sm={6} md={4}>
            <PricingCard
              title="Starter"
              price={`$${calculatePrice(16.99)}/mo`}
              yearlyTotal={billingCycle === 'yearly' ? `$${(16.99 * 12).toFixed(0)} / year` : ''}
              description="For individuals getting started"
              features={features}
              buttonText={
                isSubscribed || isTrial
                  ? subscription && subscription.plan.includes('starter')
                    ? 'You are on this plan'
                    : 'Contact us to change your plan'
                  : 'Start free Trial'
              }
              highlight={subscription ? subscription.plan.includes('starter') : false}
              onClick={
                isSubscribed || isTrial
                  ? subscription && subscription.plan.includes('starter')
                    ? undefined
                    : () => window.location.href = '/contact'
                  : () => handleStartSubscription('starter')
              }
              disabled={subscription ? subscription.plan.includes('starter') : false}
            />
          </Grid>

          {/* Pro Plan */}
          <Grid item xs={12} sm={6} md={4}>
            <PricingCard
              title="Pro"
              price={`$${calculatePrice(29.99)}/mo`}
              yearlyTotal={billingCycle === 'yearly' ? `$${(29.99 * 12).toFixed(0)} / year` : ''}
              description="For high active users"
              features={features2}
              buttonText={
                isSubscribed || isTrial
                  ? subscription && subscription.plan.includes('pro')
                    ? 'You are on this plan'
                    : 'Contact us to change your plan'
                  : 'Start free Trial'
              }
              highlight={subscription ? subscription.plan.includes('pro') : false}
              onClick={
                isSubscribed || isTrial
                  ? subscription && subscription.plan.includes('pro')
                    ? undefined
                    : () => window.location.href = '/contact'
                  : () => handleStartSubscription('pro')
              }
              disabled={subscription ? subscription.plan.includes('pro') : false}
            />
          </Grid>

          {/* Business Plan */}
          <Grid item xs={12} sm={6} md={4}>
            <PricingCard
              title="Business"
              price={`$${calculatePrice(59.99)}/mo`}
              yearlyTotal={billingCycle === 'yearly' ? `$${(59.99 * 12).toFixed(0)} / year` : ''}
              description="For large organizations"
              features={features3}
              buttonText={
                isSubscribed || isTrial
                  ? subscription && subscription.plan.includes('business')
                    ? 'You are on this plan'
                    : 'Contact us to change your plan'
                  : 'Start free Trial'
              }
              highlight={subscription ? subscription.plan.includes('business') : false}
              onClick={
                isSubscribed || isTrial
                  ? subscription && subscription.plan.includes('business')
                    ? undefined
                    : () => window.location.href = '/contact'
                  : () => handleStartSubscription('business')
              }
              disabled={subscription ? subscription.plan.includes('business') : false}
            />
          </Grid>
        </Grid>

        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Link
            href="/contact"
            variant="body2"
            sx={{ color: 'grey', textDecoration: 'underline' }}
          >
            Cancel Subscription
          </Link>
        </Box>
      </Box>

      <Box display="flex" justifyContent="center" mt={6} color="#A5A5A5">
        <Link href="/contact" variant="body2" mx={2} color={colors.brandGray400}>
          Contact
        </Link>
        <Link href="/policy" variant="body2" mx={2} color={colors.brandGray400}>
          Privacy & Policy
        </Link>
        <Link href="/terms-and-conditions" variant="body2" mx={2} color={colors.brandGray400}>
          Terms & conditions
        </Link>
      </Box>
    </Box>
  );
};

export default PricingPage;
