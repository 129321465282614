// src/components/layout/SidebarContent.tsx

import React, { useState, useEffect } from 'react';
import { Box, Typography, LinearProgress, Snackbar } from '@mui/material';
import FolderNode from './FolderNode';
import UploadModal from '../modal/UploadModal';
import useAuth from '../../hooks/useAuth';
import useFileUpload, { FileStructure } from '../../hooks/useFileUpload';
import { Project } from '../../hooks/useProjects';
import upload from "../../assets/media/upload.svg";
import { useProjectsContext } from '../../context/ProjectsContext';
import { useNavigate } from 'react-router-dom';
import useSubscriptionData from '../../hooks/useSubscriptionData';

interface SidebarContentProps {
  project?: Project | null;
}

// Function to strip paths from the structure for display
const stripPathFromStructure = (nodes: FileStructure[]): FileStructure[] => {
  return nodes.map(node => {
    const nameParts = node.name.split('/');
    const baseName = nameParts[nameParts.length - 1];
    if (node.isDirectory && node.children) {
      return {
        ...node,
        name: baseName,
        children: stripPathFromStructure(node.children),
      };
    }
    return {
      ...node,
      name: baseName,
    };
  });
};

// Function to clean the structure by removing content
const traverseAndClean = (nodes: FileStructure[], parentPath: string): FileStructure[] => {
  return nodes.map((node) => {
    const currentPath = parentPath ? `${parentPath}/${node.name}` : node.name;
    if (node.isDirectory && node.children) {
      return {
        name: currentPath,
        isDirectory: true,
        children: traverseAndClean(node.children, currentPath),
      };
    } else {
      return {
        name: currentPath,
        isDirectory: false,
      };
    }
  });
};

const SidebarContent: React.FC<SidebarContentProps> = ({ project }) => {
  const [isUploaded, setIsUploaded] = useState(!!project);
  const [openModal, setOpenModal] = useState(false);
  const [fileStructure, setFileStructure] = useState<FileStructure[]>([]);
  const [expandedFolders, setExpandedFolders] = useState<{ [key: string]: boolean }>({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { addProject, projects, loading: projectsLoading, error: projectsError } = useProjectsContext();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { subscription, loading: subscriptionLoading } = useSubscriptionData();

  const getProjectLimit = (plan: string) => {
    if (plan === 'starter') return 1;
    if (plan === 'pro') return 3;
    if (plan === 'business') return 10;
    return 0;
  };

  // Function to handle the completion of the file upload
  const handleUploadComplete = async (structure: FileStructure[]) => {
    if (!user) {
      console.error('User not authenticated.');
      return;
    }

    // Get the root folder name from the first folder in the structure (ensure it's a folder)
    const folderName = structure.length > 0 && structure[0].isDirectory ? structure[0].name : 'Project';

    // Aggregate content with full paths
    let aggregatedContent = '';
    const traverseAndAggregate = (nodes: FileStructure[], parentPath: string) => {
      nodes.forEach((node) => {
        const currentPath = parentPath ? `${parentPath}/${node.name}` : node.name;
        if (node.content && !node.isDirectory) {
          aggregatedContent += `\n\n### ${currentPath}\n\n${node.content}`;
        }
        if (node.children) {
          traverseAndAggregate(node.children, currentPath);
        }
      });
    };
    traverseAndAggregate(structure, '');

    // Clean the structure to remove content
    const cleanStructure = traverseAndClean(structure, '');

    try {
      const newProject = await addProject({
        name: folderName,
        content: aggregatedContent,
        description: '',
        structure: cleanStructure,
      });
      setIsUploaded(true);
      const displayStructure = stripPathFromStructure(structure);
      setFileStructure(displayStructure);
      setExpandedFolders(initializeExpandedFolders(displayStructure));
      setOpenModal(false);
      navigate(`/chat/${newProject.id}`);
    } catch (err) {
      console.error('Error uploading project:', err);
    }
  };

  const { handleUpload, loadingProgress, isLoading, rootFolderName } = useFileUpload(handleUploadComplete);

  const handleOpenModal = () => {
    if (subscription && !subscriptionLoading) {
      const projectLimit = getProjectLimit(subscription.plan);
      if (projects.length >= projectLimit) {
        setSnackbarOpen(true);
      } else {
        setOpenModal(true);
      }
    } else {
      const projectLimit = 0;
      if (projects.length >= projectLimit) {
        setSnackbarOpen(true);
      } else {
        setOpenModal(true);
      }
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const toggleFolder = (folderName: string) => {
    setExpandedFolders((prev) => ({
      ...prev,
      [folderName]: !prev[folderName],
    }));
  };

  const initializeExpandedFolders = (structure: FileStructure[]) => {
    const expanded: { [key: string]: boolean } = {};

    const traverse = (nodes: FileStructure[]) => {
      nodes.forEach((node) => {
        if (node.isDirectory) {
          expanded[node.name] = true;
          if (node.children) {
            traverse(node.children);
          }
        }
      });
    };

    traverse(structure);
    return expanded;
  };

  useEffect(() => {
    if (project && project.structure) {
      const displayStructure = stripPathFromStructure(project.structure);
      setFileStructure(displayStructure);
      setIsUploaded(true);
      setExpandedFolders(initializeExpandedFolders(displayStructure));
    } else {
      setFileStructure([]);
      setIsUploaded(false);
    }
  }, [project]);

  return (
    <>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="90%"
        bgcolor="#141517"
        width="100%"
        px="4%"
        marginTop="22%"
      >
        {!isUploaded ? (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            border="1px dashed #FB7EB9"
            bgcolor="rgba(251, 126, 185, 0.1)"
            borderRadius="8px"
            padding="20px"
            height="95%"
            textAlign="center"
            marginTop="10%"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.85,
              },
            }}
            onClick={handleOpenModal}
          >
            <img src={upload} alt="Upload" style={{ width: '30px', marginBottom: "8px" }} />
            <Typography color="white" mb={0}>
              Link your Github repo or Upload your folder from
            </Typography>
            <Typography color="#32B3D2" mb={1}>
              Desktop
            </Typography>
          </Box>
        ) : (
          <Box
            height="95%"
            width="100%"
            overflow="auto"
            borderRadius="8px"
            mt={2}
            p={2}
            sx={{
              bgcolor: "#1E1E1E",
              border: "none",
              maxWidth: "95%",
              overflowX: "auto",
            }}
          >
            <Typography color="#32B3D2" variant="h6" mb={2}>
              {rootFolderName || (project ? project.name : 'Project')}
            </Typography>
            {fileStructure.map((node, index) => (
              <FolderNode
                key={`${node.name}-${index}`}
                node={node}
                level={0}
                expandedFolders={expandedFolders}
                toggleFolder={toggleFolder}
              />
            ))}
          </Box>
        )}

        {!project && (
          <UploadModal open={openModal} onClose={handleCloseModal} onUpload={handleUpload} />
        )}

        {projectsError && (
          <Typography variant="body2" color="red" mt={2}>
            {projectsError}
          </Typography>
        )}

        <Snackbar
          open={snackbarOpen}
          onClose={handleSnackbarClose}
          message="You have reached the maximum number of projects for your plan. Please upgrade your plan to add more projects."
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={4000}
        />
      </Box>
    </>
  );
};

export default SidebarContent;
