// /src/components/chat/ChatHeader.tsx

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import wand from "../../assets/media/wand.svg";

interface ChatHeaderProps {
  createNewChat: () => void;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({ createNewChat }) => {
  const handleNewChat = () => {
    createNewChat();
  };

  return (
    <Box 
      display="flex" 
      justifyContent="space-between" 
      alignItems="center" 
      p={2}
      pb={1}
      sx={{
        '@media (max-width: 900px)': {
          mt: 6, // Apply margin-top of 6 for screens under 900px
        },
      }}
    >
      <Box maxWidth={{xs: '65%'}}>
        <Typography variant="h6" color="white" fontWeight={'600'}>
          Chat with Codura +
        </Typography>
        <Typography variant="body2" color="gray">
          Engage with Codura as seamlessly and effectively as you would with ChatGPT.
        </Typography>
      </Box>
      <Button
        variant="contained"
        onClick={handleNewChat}
        sx={{
          borderRadius: '50px',
          display: 'flex',
          justifyContent: 'center',
          backgroundColor: 'white',
          alignItems: 'center',
          py: '8px',
          px: '12px',
          columnGap: '7px',
          fontSize: '13px',
          textTransform: 'none',
        }}
      >
        <img src={wand} alt="Chat Wand" style={{ width: '18px' }} />
        New chat
      </Button>
    </Box>
  );
};

export default ChatHeader;
