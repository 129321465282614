// src/hooks/useChat.tsx

import { useState, useEffect, useRef } from 'react';
import useAxios from './useAxios';
import { useNavigate } from 'react-router-dom';
import useSubscriptionData, { QueryStatusData } from './useSubscriptionData';

export interface Message {
  id: number;
  chat: number;
  sender: string | null; // 'user' or 'codura' (null for bot)
  content: string;
  timestamp: string;
  is_bot: boolean;
  type: 'text' | 'file' | 'code' | 'mixed';
}

export interface Chat {
  id: number;
  project: number;
  title: string;
  created_at: string;
  messages: Message[];
}

interface UseChatReturn {
  chats: Chat[];
  selectedChat: Chat | null;
  loading: boolean;
  error: string | null;
  fetchChats: () => Promise<void>;
  setSelectedChat: React.Dispatch<React.SetStateAction<Chat | null>>;
  sendMessage: (messageContent: string) => Promise<void>;
  attachFile: (file: File) => Promise<void>;
  isBotLoading: boolean;
  cancelMessage: () => void;
  createNewChat: () => Promise<void>;
  subscriptionLoading: boolean;
  queryStatus: QueryStatusData | null;
}

const useChat = (projectId: number | undefined): UseChatReturn => {
  const axiosInstance = useAxios();
  const [chats, setChats] = useState<Chat[]>([]);
  const [selectedChat, setSelectedChat] = useState<Chat | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isBotLoading, setIsBotLoading] = useState<boolean>(false);
  
  const abortControllerRef = useRef<AbortController | null>(null);
  const navigate = useNavigate();

  const {
    subscription,
    queryStatus,
    decrementRemainingQueries,
    hasRemainingQueries,
    loading: subscriptionLoading,
    error: subscriptionError,
  } = useSubscriptionData();

  const fetchChats = async () => {
    if (!projectId) {
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.get<Chat[]>('chats/', {
        params: { project_id: projectId },
      });
      setChats(response.data);
    } catch (err: any) {
      console.error('Error fetching chats:', err);
      setError('Failed to fetch chats. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchChats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const sendMessage = async (messageContent: string) => {
    if (!selectedChat) {
      setError('No chat selected.');
      return;
    }

    if (subscriptionLoading) {
      setError('Subscription data is loading. Please wait.');
      return;
    }

    if (!hasRemainingQueries()) {
      setError('You have reached your monthly query limit. Please upgrade your plan or wait for the next billing cycle.');
      return;
    }

    // Optimistically decrement remaining queries
    decrementRemainingQueries();

    // Add the message to UI
    const temporaryMessageId = Date.now();

    const newMessage: Message = {
      id: temporaryMessageId,
      chat: selectedChat.id,
      sender: 'user',
      content: messageContent,
      timestamp: new Date().toISOString(),
      is_bot: false,
      type: 'text',
    };

    setChats(prevChats =>
      prevChats.map(chat =>
        chat.id === selectedChat.id
          ? { ...chat, messages: [...chat.messages, newMessage] }
          : chat
      )
    );

    setSelectedChat(prevChat => {
      if (!prevChat) return prevChat;
      return { ...prevChat, messages: [...prevChat.messages, newMessage] };
    });

    setIsBotLoading(true); // Show loading state

    try {
      // Send the message to the backend
      const response = await axiosInstance.post<{ messages: Message[] }>(
        'messages/',
        {
          chat: selectedChat.id,
          content: messageContent,
        },
        {
          signal: abortControllerRef.current?.signal,
        }
      );

      if (!response.data || !Array.isArray(response.data.messages)) {
        console.error('Unexpected response format:', response.data);
        setError('Unexpected response format.');
        return;
      }

      const botMessages = response.data.messages;

      setChats(prevChats =>
        prevChats.map(chat =>
          chat.id === selectedChat.id
            ? {
                ...chat,
                messages: [
                  ...chat.messages.filter(msg => msg.id !== temporaryMessageId),
                  ...botMessages,
                ],
              }
            : chat
        )
      );

      setSelectedChat(prevChat => {
        if (!prevChat) return prevChat;
        const updatedMessages = prevChat.messages.filter(
          msg => msg.id !== temporaryMessageId
        );
        return {
          ...prevChat,
          messages: [...updatedMessages, ...botMessages],
        };
      });

      // Send increment-queries/ to backend
      await axiosInstance.post('increment-queries/');
      // Note: Since we've already decremented remaining_queries locally, no further action is needed
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        // Reached limit on the backend despite local checks
        setError('You have reached your monthly query limit. Please upgrade your plan or wait for the next billing cycle.');
      } else if (err.name === 'CanceledError') {
        console.log('Message sending canceled');
      } else {
        console.error('Error sending message:', err);
        setError('Failed to send message. Please try again.');
      }

      // Remove the temporary message
      setChats(prevChats =>
        prevChats.map(chat =>
          chat.id === selectedChat.id
            ? { ...chat, messages: chat.messages.filter(msg => msg.id !== temporaryMessageId) }
            : chat
        )
      );

      setSelectedChat(prevChat => {
        if (!prevChat) return prevChat;
        return {
          ...prevChat,
          messages: prevChat.messages.filter(msg => msg.id !== temporaryMessageId),
        };
      });

      // If the error is not related to query limits, consider reverting the remaining_queries decrement
      if (!(err.response && err.response.status === 403)) {
        // Optional: Implement a function to increment remaining_queries if needed
        // This depends on your backend's implementation and whether you can reverse the decrement
      }
    } finally {
      setIsBotLoading(false);
      abortControllerRef.current = null;
    }
  };

  const createNewChat = async () => {
    if (!projectId) {
      // setError('Invalid project ID. Cannot create a new chat.');
      return;
    }

    setLoading(true);
    setError(null);
    try {
      const response = await axiosInstance.post<Chat>('chats/', {
        project: projectId,
        title: `Chat ${chats.length + 1}`,
      });

      const newChat = response.data;

      setChats(prev => [newChat, ...prev].slice(0, 5)); // Keep latest 5 chats
      setSelectedChat(newChat);
    } catch (err: any) {
      console.error('Error creating new chat:', err);
      setError('Failed to create new chat. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const attachFile = async (file: File) => {
    if (!selectedChat) {
      setError('No chat selected.');
      return;
    }

    try {
      const reader = new FileReader();
      reader.onload = () => {
        const fileContent = reader.result as string;
        console.log('Attached file:', file.name, fileContent);
        const fileMessage: Message = {
          id: Date.now(),
          chat: selectedChat.id,
          sender: 'user',
          content: file.name,
          timestamp: new Date().toISOString(),
          is_bot: false,
          type: 'file',
        };

        setChats(prevChats =>
          prevChats.map(chat =>
            chat.id === selectedChat.id
              ? { ...chat, messages: [...chat.messages, fileMessage] }
              : chat
          )
        );

        setSelectedChat(prevChat => {
          if (!prevChat) return prevChat;
          return {
            ...prevChat,
            messages: [...prevChat.messages, fileMessage],
          };
        });
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error('Error attaching file:', error);
      setError('Failed to attach file. Please try again.');
    }
  };

  const cancelMessage = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
      setIsBotLoading(false);
    }
  };

  return { 
    chats, 
    selectedChat, 
    loading, 
    error, 
    fetchChats, 
    setSelectedChat, 
    sendMessage, 
    attachFile, 
    isBotLoading,
    cancelMessage,
    createNewChat,
    subscriptionLoading,
    queryStatus, // Exposed to ChatPage.tsx
  };
};

export default useChat;
