// src/components/modal/UploadModal.tsx
import React, { useRef, ChangeEvent, useState } from 'react';
import { Box, Typography, Modal, Button, Snackbar, Alert } from '@mui/material';
import upload from "../../assets/media/uploadgray.svg";
import github from "../../assets/media/github.svg";

interface UploadModalProps {
  open: boolean;
  onClose: () => void;
  onUpload: (files: FileList | null, directoryHandle?: FileSystemDirectoryHandle) => void;
}

const UploadModal = ({ open, onClose, onUpload }: UploadModalProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Handle folder selection for non-Chromium browsers
  const handleFolderUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      onUpload(files); // Pass the files to the parent component
      onClose(); // Close the modal
    }
  };

  // Handle folder selection for Chromium browsers
  const handleFolderUploadChromium = async () => {
    try {
      // Check if the File System Access API is supported
      if ('showDirectoryPicker' in window) {
        const directoryHandle: FileSystemDirectoryHandle = await (window as any).showDirectoryPicker();
        onUpload(null, directoryHandle); // Pass the directory handle to parent component
        onClose(); // Close the modal
      } else {
        // Fallback for non-Chromium browsers
        if (inputRef.current) {
          inputRef.current.click();
        }
      }
    } catch (error) {
      console.error('Error selecting directory:', error);
    }
  };

  // Handle GitHub button click
  const handleGitHubClick = () => {
    // Show the snackbar to notify the user
    setSnackbarOpen(true);
  };

  // Close the snackbar
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
          bgcolor="white"
          borderRadius="8px"
          p={2}
          sx={{
            width: { xs: '80%', md: '450px', xl: '600px' },
            outline: 'none',
            bgcolor: '#fff',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <Typography color="black" variant="h6" mb={1}>
            Add new project
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            border="1px dashed #141517"
            bgcolor="rgba(20, 21, 23, 0.1)"
            borderRadius="8px"
            py={2}
            mb={2}
            width="100%"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                opacity: 0.85,
              },
            }}
            onClick={handleFolderUploadChromium}
          >
            <img src={upload} alt="Upload Icon" style={{ width: '24px', marginBottom: '8px' }} />
            <Typography color="#747272" variant="body2">
              Select a Project folder from your machine
            </Typography>
          </Box>

          {/* Hidden input for file/folder upload (Fallback) */}
          <input
            type="file"
            ref={inputRef}
            multiple
            hidden
            onChange={handleFolderUpload}
            style={{ display: 'none' }}
            {...{ webkitdirectory: 'true' as any }} // Use spread operator and 'as any'
          />

          <Typography color="black" variant="h6" sx={{ fontSize: '1rem' }} mb={1}>
            Or Connect with GitHub
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            sx={{ bgcolor: '#000', color: "white", textTransform: 'none', fontSize: '1rem' }}
            onClick={handleGitHubClick}
          >
            <img
              src={github}
              alt="github Icon"
              style={{ width: '20px', marginRight: '14px', paddingTop: '3px', paddingBottom: '3px' }}
            />
            Connect with GitHub
          </Button>

          <Typography color="#A5A5A5" variant="caption" mt={1}>
              Using Safari? Avoid selecting large files or libraries for better performance.
          </Typography>
        </Box>
      </Modal>

      {/* Snackbar for displaying message */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="info" sx={{ width: '100%' }}>
          Sorry, you can't use this method at the moment.
        </Alert>
      </Snackbar>
    </>
  );
};

export default UploadModal;
