// /src/components/chat/ChatInput.tsx

import React, { useState } from 'react';
import { Box, TextField, IconButton, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import StopIcon from '@mui/icons-material/Stop';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { colors } from '../../config/theme';

// Define props type
interface ChatInputProps {
  onSendMessage: (message: string) => void;
  onAttachFile: (file: File) => void;
  isBotLoading: boolean;
  onCancelMessage: () => void;
}

// Define the component with typed props
const ChatInput: React.FC<ChatInputProps> = ({ onSendMessage, onAttachFile, isBotLoading, onCancelMessage }) => {
  const [message, setMessage] = useState<string>('');

  // Handle send message
  const handleSendClick = () => {
    if (message.trim()) {
      onSendMessage(message);
      setMessage(''); // Clear the input
    }
  };

  // Handle stop generating
  const handleStopClick = () => {
    onCancelMessage();
  };

  // Handle pressing Enter to send message
  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey && !isBotLoading) {
      event.preventDefault();
      handleSendClick();
    }
  };

  // Handle file attachment
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      onAttachFile(event.target.files[0]);
    }
  };

  return (
    <Box display="flex" alignItems="center" p={2} width={{ xs: '100%', md: "100%" }} position="relative">
      {/* Attach icon */}
      <IconButton
        color="secondary"
        disabled={true} //disabled={isBotLoading}
        sx={{
          position: 'absolute',
          left: '2.5%',
          bottom: '24px',
          zIndex: 1,
        }}
        component="label"
      >
        <input hidden type="file" onChange={handleFileChange} />
        <AttachFileIcon />
      </IconButton>

      {/* TextField */}
      <TextField
        variant="outlined"
        fullWidth
        multiline
        minRows={1}
        maxRows={4}
        placeholder="Message Codura"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyPress={handleKeyPress}
        sx={{
          backgroundColor: '#1D1E22',
          borderRadius: '50px',
          paddingLeft: { xs: '20px', md: '50px' },
          paddingRight: { xs: '20px', md: '50px' },
          input: { color: 'white' },
          '.MuiOutlinedInput-root': {
            '& fieldset': { border: 'none' },
            borderRadius: '50px',
            '&:hover fieldset': { border: 'none' },
            '&.Mui-focused fieldset': { border: 'none' },
          },
        }}
        InputProps={{
          style: { color: 'white' },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={isBotLoading ? handleStopClick : handleSendClick}
                sx={{
                  position: 'absolute',
                  right: '-3.5%',
                  bottom: '10px',
                  transform: 'rotate(-25deg)',
                  zIndex: 1,
                  color: isBotLoading ? 'white' : colors.brandGray400,  // Change color conditionally
                }}
              >
                {isBotLoading ? <StopIcon /> : <SendIcon />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default ChatInput;
