// src/components/dashboard/ProjectCard.tsx

import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Close';
import folder from "../../assets/media/foldericon.svg";
import upload from "../../assets/media/upload.svg";
import { useNavigate } from 'react-router-dom';

interface Project {
  id: number;
  name: string;
  description?: string;
  content?: string;
  structure?: any;
}

interface ProjectCardProps {
  project: Project;
  onAddProjectClick?: () => void; // Optional: Only for "Add Project"
  onDelete?: () => void; // Optional: Only for "Delete Project"
}

const ProjectCard = ({ project, onAddProjectClick, onDelete }: ProjectCardProps) => {
  const isAddProject = project.name === 'Add Project';
  const navigate = useNavigate();

  const handleClick = () => {
    if (isAddProject && onAddProjectClick) {
      onAddProjectClick(); // Trigger modal if it's "Add Project"
    } else {
      navigate(`/chat/${project.id}`); // Navigate to the project chat
    }
  };

  return (
    <Box
      position="relative"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgcolor={isAddProject ? "#333" : "rgba(251, 126, 185, 0.2)"}
      borderRadius="10px"
      py={4}
      height="100%"
      sx={{ cursor: 'pointer' }}
      onClick={handleClick}
    >
      {/* Delete Icon */}
      {!isAddProject && onDelete && (
        <IconButton
          onClick={(e) => {
            e.stopPropagation(); // Prevent click from triggering navigation
            onDelete();
          }}
          size="small"
          sx={{ position: 'absolute', top: 4, right: 4, color: 'white' }}
        >
          <DeleteIcon />
        </IconButton>
      )}

      {/* Folder Icon or Upload Icon */}
      <Box mb={2} width="50px" height="50px" display="flex" justifyContent="center" alignItems="center">
        <img
          src={isAddProject ? upload : folder}
          alt={isAddProject ? "Add Project" : "Project Folder"}
          style={{ width: "80%", height: "100%" }}
        />
      </Box>
      <Typography color="white" variant="h6">
        {project.name}
      </Typography>
    </Box>
  );
};

export default ProjectCard;
