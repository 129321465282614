import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles' {
  interface PaletteOptions {
    customColors?: {
      [key: string]: string
    }
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#FB7EB9',
    },
    secondary: {
      main: '#32B3D2',
    },

    customColors: {
      gray: '#FF6711',
      customColor2: '#your_custom_color2_here',
    },
  },
  typography: {
    fontFamily: 'Inter, Arial, sans-serif',
  },
})

export default theme
