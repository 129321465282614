import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

interface ExternalPageProps {
  page: string;
}

const ExternalPage: React.FC<ExternalPageProps> = ({ page }) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe) {
      iframe.onload = () => {
        console.log("Iframe loaded successfully");

        // Listen for clicks on links and handle navigation within the iframe
        try {
          const iframeDoc = iframe.contentDocument || iframe.contentWindow?.document;
          if (iframeDoc) {
            const links = iframeDoc.querySelectorAll('a');

            links.forEach((link) => {
              link.addEventListener('click', (e) => {
                e.preventDefault();
                const target = e.currentTarget as HTMLAnchorElement;

                if (target && target.getAttribute('href')) {
                  let newPath = target.getAttribute('href');

                  if (newPath) {
                    // Check if the link is an external URL
                    const isExternal = newPath.startsWith('http');
                    if (isExternal) {
                      // Open external links in a new tab
                      window.open(newPath, '_blank');
                    } else {
                      // Remove the ".html" extension for internal navigation
                      newPath = newPath.replace('.html', '');

                      // Navigate to the root path for "index" page, otherwise to the new path
                      if (newPath === 'index') {
                        navigate('/', { replace: true });
                      } else {
                        navigate(`/${newPath}`, { replace: true });
                      }
                    }
                  }
                }
              });
            });

            // Handle "Launch" button click inside the iframe
            const launchButton = iframeDoc.querySelector('.main-button.nav');
            if (launchButton) {
              launchButton.addEventListener('click', (e) => {
                e.preventDefault();
                // Replace this with your actual logic for checking user login status
                navigate('/dashboard');
              });
            }
          }
        } catch (error) {
          console.error("Cross-origin access error: ", error);
        }
      };
    }

    const handleIframeMessage = (event: MessageEvent) => {
      // Check the origin of the message for security reasons
      if (event.origin !== window.location.origin) return;

      // Handle the messages received from the iframe (if needed)
      if (event.data === 'form-submitted') {
        console.log('Form submitted successfully in iframe');
        // No custom navigation needed; Web3Forms will handle the response
      }
    };

    // Add the event listener to listen for messages from the iframe
    window.addEventListener('message', handleIframeMessage);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('message', handleIframeMessage);
    };
  }, [page, navigate]);

  return (
    <iframe
      ref={iframeRef}
      src={`/external/codura%20landing/${page}.html`}
      title={page}
      style={{ width: '100%', height: '100vh', border: 'none' }}
    />
  );
};

export default ExternalPage;
